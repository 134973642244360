import React from "react";
import { Link } from "gatsby";

// MATERIAL UI
import { styled } from "@mui/system";
import { Box, Typography } from "@mui/material";

const BlogCard = ({ title, image, caption, slug }) => {
  return (
    <Container href={slug}>
      <img src={image} alt={title} />
      <Typography variant="h5">{title}</Typography>
      <Typography>{caption}</Typography>
    </Container>
  );
};

const Container = styled(Link)({
  display: "flex",
  flexDirection: "column",

  color: "#FFFFFF",

  img: {
    width: "100%",
    borderRadius: 12,
    marginBottom: 8,
  },
});

export default BlogCard;
