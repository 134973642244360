import React from "react";
import { graphql } from "gatsby";

// MATERIAL UI
import { styled } from "@mui/system";

// COMPONENTS
import Layout from "components/Layout";
import BlogCard from "../components/blog/BlogCard";

const Blog = ({ data }) => {
  const { posts } = data.blog;

  return (
    <Layout pageTitle="Blog Posts" metaTitle="Blog Posts">
      <BlogsContainer>
        {posts.map((post) => (
          <BlogCard
            title={post.frontmatter.title}
            image={post.frontmatter.image}
            caption={post.frontmatter.caption}
            slug={post.fields.slug}
          />
        ))}
      </BlogsContainer>
    </Layout>
  );
};

export const pageQuery = graphql`
  query MyQuery {
    blog: allMarkdownRemark {
      posts: nodes {
        fields {
          slug
        }
        frontmatter {
          date(fromNow: true)
          title
          caption
          image
        }
        excerpt
        id
      }
    }
  }
`;

const BlogsContainer = styled("div")({
  display: "grid",
  gridTemplateColumns: "1fr 1fr 1fr",
  gridGap: 24,
  minHeight: 500,
});

export default Blog;
